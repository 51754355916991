const Images = {
    templateImage1: require('../../assets/img/bg1.jpg'),
    templateImage2: require('../../assets/img/bg3.jpg'),
    templateImage3: require('../../assets/img/bg4.jpg'),
    templateImage4: require('../../assets/img/bg5.jpg'),
    templateImage5: require('../../assets/img/bg6.jpg'),
    templateImage6: require('../../assets/img/bg7.jpg'),
    templateImage7: require('../../assets/img/bg8.jpg'), 
}

export default Images;