import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import CloneModal from "../Modals/CloneModal/CloneModal";
import PreviewModal from "../Modals/PreviewModal/PreviewModal";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Colors } from "../../config";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    marginBottom: 20,
    boxShadow: "5px 5px 20px #aaa",
    minHeight: 600,
    borderRadius: 18,
  },
  media: {
    height: 300,
  },
  title: {
    color: "#000",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  button: {
    color: "#6927ff",
    fontSize: 16,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  frameContainer: {
    height: "500px",
    width: "400px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  frame: {
    width: "100%",
    height: "100%",
    border: "none",
  },
  loadingContainer: {
    width: "100%",
    height: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export function StatsCard(props) {
  const classes = useStyles();

  const [state, setState] = useState({
    showPopup: false,
    showPopupPreview: false,
    loading: true,
    anchorEl: null,
  });

  const handleLoad = () => {
    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };

  const togglePopup = () => {
    setState((prevState) => ({
      ...prevState,
      showPopup: !prevState.showPopup,
    }));
  };

  const togglePopupPreview = () => {
    setState((prevState) => ({
      ...prevState,
      showPopupPreview: !prevState.showPopupPreview,
    }));
  };

  const handleClick = (event) => {
    setState({
      ...state,
      anchorEl: event.currentTarget,
    });
  };

  const handleClosePopover = () => {
    setState({
      ...state,
      anchorEl: null,
    });
  };

  const open = Boolean(state.anchorEl);
  const id = open ? "delete-popover" : undefined;

  let decodedHtml = "";
  try {
    const encodedHtml = props.frame;
    decodedHtml = decodeURIComponent(escape(atob(encodedHtml)));
  } catch (e) {
    console.error("Error decoding HTML content:", e);
  }

  // Add UTF-8 meta tag to the decoded HTML
  const htmlWithMeta = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Template</title>
</head>
<body>
    ${decodedHtml}
</body>
</html>`;

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <div className={classes.frameContainer}>
            {state.loading && (
              <div className={classes.loadingContainer}>
                <Spinner
                  style={{ width: "3rem", height: "3rem" }}
                  color="primary"
                />
              </div>
            )}
            <iframe
              onLoad={handleLoad}
              srcDoc={htmlWithMeta}
              className={classes.frame}
              title={props.statsHeading}
              style={{ display: state.loading ? "none" : "block" }}
            />
          </div>
          <CardContent>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="h2"
            >
              {props.statsHeading}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.statsText}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.buttonContainer}>
          <Button
            size="small"
            style={{ backgroundColor: Colors.LightBlue }}
            onClick={togglePopupPreview}
          >
            {props.statsBtn1Text}
          </Button>
          <Button
            size="small"
            style={{ backgroundColor: Colors.Green }}
            onClick={togglePopup}
          >
            {props.statsBtn2Text}
          </Button>
          <Button
            size="small"
            style={{ backgroundColor: "#ed3833", fontWeight: 1000 }}
            onClick={handleClick}
          >
            Delete Template
          </Button>
        </CardActions>
        <Popover
          id={id}
          open={open}
          anchorEl={state.anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <p
            style={{
              fontSize: 12,
              fontWeight: "bold",
              fontFamily: "Montserrat",
            }}
          >
            Are you sure you want to delete this template?
          </p>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn"
              onClick={handleClosePopover}
              style={{
                margin: 5,
                backgroundColor: "#424874",
                fontWeight: "normal",
              }}
            >
              Cancel
            </button>
            <button
              className="btn"
              onClick={props.onClickDelete} // Ensure you have this prop defined
              style={{
                margin: 5,
                backgroundColor: "#e41749",
                fontWeight: "normal",
              }}
            >
              Yes, delete
            </button>
          </div>
        </Popover>
      </Card>
      {state.showPopup ? <CloneModal html={props.statsHTML} /> : null}
      {state.showPopupPreview ? (
        <PreviewModal name={props.statsHeading} html={props.statsHTML} />
      ) : null}
    </>
  );
}

export default StatsCard;
