import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { createRoot } from "react-dom/client";

import Index from "./views/Index.js";
import LoginPage from "./views/Pages/LoginPage.js";
import SignUpPage from "./views/Pages/SignUpPage.js";
import ForgotPasswordPage from "./views/Pages/ForgotPasswordPage.js";
import Dashboard from "./views/Pages/Dashboard";
import AdminLayout from "./views/Admin.jsx";
import ResetPassword from "./views/Pages/ResetPassword.js";

// Main styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
// Dashboard style
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import { Provider } from "react-redux";
import store from "./store";
import PageDetail from "./views/Pages/PageDetail.jsx";
import EditAsHTML from "./views/Pages/EditAsHTML.jsx";
import Logout from "./views/Pages/Logout.js";

// Create root
const root = createRoot(document.getElementById("root"));

// Render the application inside the root
root.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/index" component={Index} />
        <Route exact path="/login-page" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/admin/pageDetails" component={PageDetail} />
        <Route exact path="/admin/editAsHTML" component={EditAsHTML} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/signUp-page" component={SignUpPage} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/index" />
      </Switch>
    </Router>
  </Provider>
);
