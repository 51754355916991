import React from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AuthMiddleware from "../../store/middlewares/AuthMiddleware";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailFocus: false,
      email: "",
    };
  }

  handleInput = e => {
    //e.preventDefault();
    console.log(e.target.value);
    e.preventDefault();
    const name = e.target.value;
    const value = e.target.value;
    this.setState({
      [name]: value
    });
  }


  ForgotPass = (e, email) => {
    e.preventDefault();
    // if (email) {
    //   this.props.ForgotPass(email);
    // }

    if (this.state.email === "") {
      NotificationManager.warning("Email is required")
      return false;
    }
    const data = { email: this.state.email };
    axios.post("https://magicpages.propstory.com/user/forgotPassword", data)
      .then(result => {
        NotificationManager.success("Please check the email")
      }).catch(err => {
        if (err.response && err.response.status === 404)
          NotificationManager.error(err.response.data.msg);
        else
          NotificationManager.error("Something Went Wrong");
        this.setState({ errors: err.response })
      });

  };

  handleClose(event, reason) {

    if (reason === 'clickaway') {
      return;
    }

    this.setState({ signedUp: false });
  };


  render() {
    const { email, password } = this.state;
    return (
      <>
        {console.log(localStorage.getItem("userId"))}
        <div className="container-login100">
          <div className="wrap-login100 p-b-160 p-t-50">
            <form className="login100-form validate-form">
              <span className="login100-form-title p-b-43">Forgot Password</span>
              <div
                className="wrap-input100-1 rs1 validate-input"
                data-validate="Email is required"
              >
                <input
                  placeholder="Enter Email"
                  onFocus={() => this.setState({ emailFocus: true })}
                  onBlur={() => this.setState({ emailFocus: false })}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  className="input100"
                  type="text"
                  name="Email"
                />
              </div>

              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn"
                  onClick={(e) => this.ForgotPass(e, email)}
                  to="/admin/dashboard"
                >
                  Forgot Password
                </button>
              </div>

            </form>
            <NotificationContainer></NotificationContainer>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    ForgotPass: (email) => {
      dispatch(AuthMiddleware.ForgotPass(email));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
