import React from "react";
import { connect } from "react-redux";
import AuthMiddleware from "../../store/middlewares/AuthMiddleware";
import { GoogleLogin } from 'react-google-login';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { colors } from "@material-ui/core";
import history from "../../config/history";
// import GoogleButton from 'react-google-button'


var loggedInUser;

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      emailFocus: false,
      passwordFocus: false,
      email: "",
      password: "",
      user: {},
      storagedata: {}
    };
  }

  // componentDidMount() {
  //   loggedInUser = localStorage.getItem("user");
  //   var foundUser = JSON.parse(loggedInUser)
  //   var loggedResult = foundUser;
  //   var finalresut = loggedInUser ?
  //     loggedResult : "user not found"
  //   console.log("already logged in");
  //   return finalresut

  // }

  componentWillMount() {
    loggedInUser = localStorage.getItem("user_data");
    if (loggedInUser) {
      //   const foundUser = JSON.parse(loggedInUser);
      // this.setState({ storageData: localStorage.getItem("user_data") });
      history.push('/admin/dashboard')

    }

  }

  // componentWillUnmount() {
  //   console.log("unmount working===>");
  // }

  // UNSAFE_componentWillUpdate() {
  //   console.log("Did update works===>");
  // }

  // // logout the user
  // handleLogout = () => {
  //   // setUser({});
  //   this.email("");
  //   this.password("");
  //   localStorage.clear();
  // };

  SignIn = (e, email, password) => {
    e.preventDefault();
    if (email && password) {
      this.props.SignIn(email, password);
    }
  };




  responseSuccessGoogle = (response) => {
    console.log(response.tokenId);
    axios({
      method: "POST",
      url: "https://magicpages.propstory.com/user/googlelogin",
      data: { tokenId: response.tokenId }
    }).then(response => {
      console.log("Google login success", response);
      console.log(response.data)
      console.log(process.env)
      this.props.history.push('/admin/dashboard')
    }).catch((error) => {
      console.log(error);
    })
  }

  responseErrorGoogle = (response) => {
    console.log("Pop-up error");
    console.log(response);
  }



  render() {
    const { email, password, user } = this.state;


    return (
      <>
        {console.log(localStorage.getItem("userId"))}

        <div className="container-login100">
          <div className="wrap-login100 p-b-160 p-t-50">
            <span className="login100-form-title p-b-43">Sign in With</span>
            <div className="text-center">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Google"
                onSuccess={this.responseSuccessGoogle}
                onFailure={this.responseErrorGoogle}
                cookiePolicy={'single_host_origin'}
                className="google-btn"
              />
            </div>
            <br></br>
            <form className="login100-form validate-form">

              <span className="login100-form-title p-b-43">OR</span>
              <div
                className="wrap-input100 rs1 validate-input"
                data-validate="Email is required"
              >
                <input
                  placeholder="Enter Email"
                  onFocus={() => this.setState({ emailFocus: true })}
                  onBlur={() => this.setState({ emailFocus: false })}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  className="input100"
                  type="text"
                  name="Email"
                />
              </div>
              <div
                className="wrap-input100 rs2 validate-input"
                data-validate="Password is required"
              >
                <input
                  placeholder="Enter Password"
                  onFocus={() => this.setState({ passwordFocus: true })}
                  onBlur={() => this.setState({ passwordFocus: false })}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  className="input100"
                  type="password"
                  name="pass"
                />
              </div>
              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn"
                  onClick={(e) => this.SignIn(e, email, password)}
                  to="/admin/dashboard"
                >
                  Sign in
                </button>
              </div>

              <div className="text-center w-full p-t-23">
                <a href="/signUp-page" className="txt1">
                  Don't have an account? Create account!
                </a>
              </div>
              <div className="text-center w-full p-t-23">
                <a href="/forgot-password" className="txt1">
                  Forgot password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </>
    );


  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    SignIn: (email, password) => {
      dispatch(AuthMiddleware.SignIn(email, password));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
