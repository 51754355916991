import React, { Component } from 'react'
import history from '../../config/history';

export default class Logout extends Component {
    componentDidMount() {
        localStorage.removeItem("user_data");
        history.push('/login-page')
    }

    render() {
        return null
    }
}
