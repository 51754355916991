import React from "react";
import { connect } from "react-redux";
import { DataBaseMiddleware } from "../../store/middlewares";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  welcomeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh", // Center vertically
    backgroundColor: "maroon", // Set background color to Maroon
  },
  welcomeMessage: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "white", // Set text color to white
  },
 
});

function Dashboard({ userId }) {
  const classes = useStyles();

  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.welcomeMessage}>
        Welcome to Magic Page{userId}!
      </div>
    </div>
  );
}

function mapStateToProps(states) {
  return {
    userId: states.AuthReducer.user.id,
    pages: states.DataBaseReducer.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetTemplates: (userId) => {
      dispatch(DataBaseMiddleware.GetTemplates(userId));
    },
    GetPages: (userId) => {
      dispatch(DataBaseMiddleware.GetPages(userId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
