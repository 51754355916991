import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Spinner } from "reactstrap";
import { Colors } from "../../config";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Popover from "@material-ui/core/Popover";

export class StatsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      loading: true,
    };

    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
  };

  Loaded = () => {
    this.setState({ loading: false });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "delete-popover" : undefined;
    const { onClickEdit, onClickDelete } = this.props;

    let decodedHtml = "";
    try {
      const encodedHtml = this.props.frame;
      decodedHtml = decodeURIComponent(escape(atob(encodedHtml)));
    } catch (e) {
      console.error("Error decoding HTML content:", e);
    }

    // Add UTF-8 meta tag to the decoded HTML
    const htmlWithMeta = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Template</title>
</head>
<body>
    ${decodedHtml}
</body>
</html>`;

    return (
      <div className="card card-stats" style={{ borderRadius: 28 }}>
        <div className="content">
          <Row md={8}>
            <Col md={12}>
              {this.state.loading ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "100%", height: 500, alignItems: "center" }}
                >
                  {" "}
                  <Spinner color="black" />{" "}
                </div>
              ) : null}
              <iframe
                onLoad={this.Loaded}
                title="Preview"
                width="90%"
                scroll="no"
                height={this.state.loading ? "0" : "500"}
                style={{
                  marginTop: 20,
                  borderRadius: 16,
                  border: "none",
                  overflow: "hidden",
                }}
                srcDoc={htmlWithMeta}
              />
            </Col>
          </Row>
          <div className="heading">
            <h4 style={{ color: this.props.statsColor, fontWeight: "bolder" }}>
              {this.props.statsHeading}
            </h4>
          </div>
          <div className="text">{this.props.statsText}</div>
          <hr />
          <div className="footer">
            <div className="btns" style={{ display: "inline" }}>
              <Button
                className="btn"
                style={{ backgroundColor: Colors.Green, marginRight: 40 }}
                onClick={onClickEdit}
              >
                {" "}
                <EditIcon fontSize="small" style={{ marginRight: 4 }} />
                {this.props.statsBtn1Text}
              </Button>
              <Button
                aria-describedby={id}
                className="btn"
                style={{ backgroundColor: "#ed3833", fontWeight: 1000 }}
                onClick={this.handleClick}
              >
                {" "}
                <DeleteOutlineIcon
                  fontSize="small"
                  style={{ marginRight: 4 }}
                />
                {this.props.statsBtn2Text}
              </Button>
              <i className={this.props.className}></i>
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Are you sure you want to delete this page?
                </p>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    className="btn"
                    onClick={this.handleClosePopover}
                    style={{
                      margin: 5,
                      backgroundColor: "#424874",
                      fontWeight: "normal",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    class="btn"
                    onClick={onClickDelete}
                    style={{
                      margin: 5,
                      backgroundColor: "#e41749",
                      fontWeight: "normal",
                    }}
                  >
                    Yes, delete
                  </button>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsCard;
