export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const FORGOTPASS = 'FORGOTPASS';
export const FORGOTPASS_SUCCESS = 'FORGOTPASS_SUCCESS';
export const FORGOTPASS_FAILURE = 'FORGOTPASS_FAILURE';

export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';


export const ADDCOMPANY = 'ADDCOMPANY';
export const ADDCOMPANY_SUCCESS = 'ADDCOMPANY_SUCCESS';
export const ADDCOMPANY_FAILURE = 'ADDCOMPANY_FAILURE';

export const GETTEMPLATES = 'GETTEMPLATES';
export const GETTEMPLATES_SUCCESS = 'GETTEMPLATES_SUCCESS';
export const GETTEMPLATES_FAILURE = 'GETTEMPLATES_FAILURE';

export const UPLOADTEMPLATES = 'UPLOADTEMPLATES';
export const UPLOADTEMPLATES_SUCCESS = 'UPLOADTEMPLATES_SUCCESS';
export const UPLOADTEMPLATES_FAILURE = 'UPLOADTEMPLATES_FAILURE';

export const GETPAGES = 'GETPAGES';
export const GETPAGES_SUCCESS = 'GETPAGES_SUCCESS';
export const GETPAGES_FAILURE = 'GETPAGES_FAILURE';

export const LOADER_TRUE = 'LOADER_TRUE';
export const LOADER_FALSE = 'LOADER_FALSE';

export const CLONETEMPLATE = 'CLONETEMPLATE';