import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import Button from "@material-ui/core/Button";
import exportFromJSON from "export-from-json";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

const updateAllocation = () => {
  console.log("data");
};

const columns = [
  { id: "ID", label: "ID", display: "none" },
  { id: "NAME", label: "NAME", minWidth: 200, align: "center", maxWidth: 200 },
  {
    id: "EMAIL",
    label: "EMAIL",
    minWidth: 200,
    align: "center",
    maxWidth: 200,
  },
  {
    id: "PHONE",
    label: "PHONE",
    minWidth: 200,
    align: "center",
    maxWidth: 200,
  },
  {
    id: "DATE",
    label: "DATE",
    minWidth: 200,
    align: "center",
    maxWidth: 200,
  },
  {
    id: "TIME",
    label: "TIME",
    minWidth: 200,
    align: "center",
    maxWidth: 200,
  },
  {
    id: "URL",
    label: "URL",
    minWidth: 200,
    align: "start",
    hidden: false,
    overflow: "ellipsis",
    maxWidth: 200,
  },
];

function createData(ID, NAME, EMAIL, PHONE, DATE, TIME, URL) {
  return { ID, NAME, EMAIL, PHONE, DATE, TIME, URL };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: 580,
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setLoading(props.data ? false : true);
  }, [props.data]);

  const rows = useMemo(() => {
    if (!props.data) return [];

    // Filter data based on the selected date range
    let filteredData = props.data;
    if (startDate && endDate) {
      filteredData = filteredData.filter((lead) => {
        const leadDate = new Date(lead.created_time);
        return leadDate >= startDate && leadDate <= endDate;
      });
    }

    return filteredData.map((lead) => {
      const leadDate = new Date(lead.created_time);
      const formattedDate = `${leadDate.getDate()}/${
        leadDate.getMonth() + 1
      }/${leadDate.getFullYear()}`;
      const formattedTime = `${leadDate.getHours()}:${leadDate.getMinutes()}:${leadDate.getSeconds()}`;
      return createData(
        lead.id,
        lead.name,
        lead.email,
        lead.phone,
        formattedDate,
        formattedTime,
        lead.page_url
      );
    });
  }, [props.data, startDate, endDate]);

  const downloadCsv = () => {
    var url = "";
    const parsedData = [];
    console.log("props-data", props.data);
    props.data.map((resData) => {
      url = resData.page_url;
      return parsedData.push({
        NAME: resData.name,
        EMAIL: resData.email,
        PHONE: resData.phone,
        DATE: new Date(resData.created_time).toLocaleDateString("en-GB"),
        TIME: new Date(resData.created_time).toLocaleTimeString("en-GB"),
        URL: resData.page_url,
      });
    });
    const data = parsedData;
    console.log("csv-datas", data);

    const fileName = props.url;
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  console.log("Start Date:", startDate);
  console.log("End Date:", endDate);

  return (
    <Paper className={classes.root}>
      {console.log("ON backup leads page")}
      {loading ? (
        <CircularProgress />
      ) : (
        rows.length > 1 && (
          <>
            <Button
              onClick={() => downloadCsv()}
              variant="contained"
              color="secondary"
              startIcon={<AssignmentOutlinedIcon />}
              style={{
                position: "fixed",
                right: 20,
                bottom: 20,
                fontWeight: 600,
              }}
            >
              <Typography
                component="h6"
                variant="subtitle"
                style={{ textTransform: "none", color: "#fff" }}
              >
                Download as csv
              </Typography>
            </Button>
            <div
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Typography variant="p" style={{ fontWeight: "bold" }}>
                Filter Date:
              </Typography>
              <div>
                <div style={{ display: "flex", marginLeft: "20px" }}>
                  <div style={{ marginRight: 10, zIndex: "5" }}>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      dateFormat="dd/MM/yyyy"
                      className="input-border"
                    />
                  </div>
                  <div style={{ zIndex: "5" }}>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="End Date"
                      minDate={startDate}
                      dateFormat="dd/MM/yyyy"
                      className="input-border"
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          display: column.display,
                          maxWidth: column.maxWidth,
                        }}
                        hidden={column.hidden}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .filter(
                      (row) => row.NAME && row.EMAIL !== "" && row.PHONE !== ""
                    )
                    .map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <TableCell
                              style={{
                                display: column.display,
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                                overflow: "auto",
                                textOverflow: "initial",
                                wordWrap: "normal",
                              }}
                              key={column.id}
                              align={column.align}
                              hidden={column.hidden}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )
      )}
    </Paper>
  );
}
