import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { FiHash } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Navbar } from "react-bootstrap";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (left, open) => (event) => {
    setState({ ...state, left: open });
  };

  const handleDrawerClose = () => {
    setState({ ...state, left: false });
  };

  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      {console.log("props starts")}
      {console.log(props.routes)}

      <List>
        {props.routes.map((props, key) => {
          if (!props.redirect)
            return (
              <li
                className={
                  props.upgrade
                    ? "active active-pro"
                    : activeRoute(props.layout + props.path)
                }
                key={key}
              >
                <NavLink
                  to={props.layout + props.path}
                  className="nav-link d-flex"
                  activeClassName="active"
                  style={{ alignItems: "center" }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItem button key={props.name}>
                    <ListItemIcon>
                      {<FiHash size={24} color="#6927ff" />}
                      {/* <i className={props.icon} style={{fontSize: 24}} /> */}
                    </ListItemIcon>
                    <ListItemText
                      style={{ fontWeight: "bold" }}
                      primary={props.name}
                    />
                  </ListItem>
                </NavLink>
              </li>
            );
          return null;
        })}
      </List>
    </div>
  );

  return (
    <>
      <Navbar bg="light" expand="xl">
        <Navbar.Brand>
          <Button
            className="Button"
            style={{ outline: "none" }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon color="secondary" fontSize="large" />
          </Button>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 20,
              fontFamily: "Montserrat",
              verticalAlign: "middle",
            }}
          >
            PROPSTORY MAGIC PAGES
          </p>
        </Navbar.Brand>
      </Navbar>
      <Drawer anchor="left" open={state["left"]} onClose={toggleDrawer(false)}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon color="primary" />
          </IconButton>
        </div>
        {list("left")}
      </Drawer>
    </>
  );
}
