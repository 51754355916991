import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PreviewModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  // Function to decode Base64 encoded HTML
  const decodeBase64 = (base64String) => {
    return decodeURIComponent(escape(atob(base64String)));
  };

  // Decode HTML content from props
  const decodedHtml = decodeBase64(props.html);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <iframe
            title="Preview"
            srcDoc={decodedHtml}
            width="90%"
            height="700"
            className="Image"
            style={{ borderWidth: 8, borderRadius: 12, borderColor: "#fff" }}
          />
        </Fade>
      </Modal>
    </div>
  );
}
