import React from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AuthMiddleware from "../../store/middlewares/AuthMiddleware";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
            success: false,
            updated: false,
            isLoading: true,
            error: false

        };
    }

    async componentDidMount() {
        // console.log('from react');
        // console.log('this.props.match.params.token');
        // console.log(this.props.match);
        // console.log(this.props.match.params.token);
        const query = new URLSearchParams(this.props.location.search);
        // console.log(query)
        const token = query.get('token')
        const user_id = query.get('user_id')
        this.setState({
            user: user_id
        })
        // console.log("token and id")//123
        // console.log(token)
        // console.log(user_id)
        await axios
            .get("https://magicpages.propstory.com/user/reset/", {
                params: {
                    token: token,
                    user_id: user_id
                },
            }).then(response => {
                // console.log('success from react')
                // console.log(response);
                if (response.data.message === "password reset link ok") {
                    this.setState({
                        id: response.data.user_id,
                        update: false,
                        success: true,
                        isLoading: false,
                        error: false
                    })
                } else {
                    this.setState({
                        update: false,
                        success: false,
                        isLoading: false,
                        error: true
                    });
                }
            }).catch(error => {
                console.log('error from react')
                console.log(error.data);
            });
    }

    ForgotPass = (e, email) => {
        e.preventDefault();
        // if (email) {
        //   this.props.ForgotPass(email);
        // }

        if (this.state.email === "") {
            NotificationManager.warning("Email is required")
            return false;
        }
        const data = { email: this.state.email };
        axios.post("https://magicpages.propstory.com/user/forgotPassword", data)
            .then(result => {
                NotificationManager.success("Please check the email")
            }).catch(err => {
                if (err.response && err.response.status === 404)
                    NotificationManager.error(err.response.data.msg);
                else
                    NotificationManager.error("Something Went Wrong");
                this.setState({ errors: err.response })
            });

    };

    // async componentWillMount() {
    //     console.log('this.props.match.params.token from will mount');
    //     console.log(this.props.match.params);
    // }

    handleChange = event => {
        // console.log("on pass change")
        // console.log(event.target.value)
        this.setState({
            password: event.target.value,
        })
    }


    updatePassword = e => {
        // console.log('from pudate password')
        // console.log(this.state.password)
        // console.log(this.state.user)
        // console.log('****************')
        e.preventDefault();
        axios
            .post("https://magicpages.propstory.com/user/updatePassword", {
                password: this.state.password,
                user_id: this.state.user
            }).then(response => {
                // console.log(response);
                if (response.data.message === "password updated") {
                    NotificationManager.success("Your password has been Changed, try to login")
                    this.setState({
                        updated: true,
                        error: false,
                    });
                } else {
                    NotificationManager.error("Try Again");
                    this.setState({
                        updated: false,
                        error: true,
                    });
                }
                // console.log("updated password");
            }).catch(error => {
                console.log(error.data);
            })
    }

    render() {
        const { password, success, error, email } = this.state;

        if (success === true) {
            return (
                <>
                    {console.log(localStorage.getItem("userId"))}
                    <div className="container-login100">
                        <div className="wrap-login100 p-b-160 p-t-50">
                            <form className="login100-form validate-form" onSubmit={this.updatePassword}>
                                <span className="login100-form-title p-b-43">Reset Password</span>
                                <div
                                    className="wrap-input100-1 rs1 validate-input"
                                    data-validate="Enter Password"
                                >
                                    <input
                                        placeholder="Enter new password"
                                        onChange={this.handleChange}
                                        className="input100"
                                        type="password"
                                        name="pass"
                                    />
                                </div>

                                <div className="container-login100-form-btn">
                                    <button
                                        className="login100-form-btn"
                                        // onClick={(e) => this.ForgotPass(e, email)}
                                        onClick={(e) => this.updatePassword(e, password)}
                                        to="/login-page"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                                <div className="text-center w-full p-t-23">
                                    <a href="/login-page" className="txt1">
                                        login
                                </a>
                                </div>

                            </form>
                            <NotificationContainer></NotificationContainer>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {console.log(localStorage.getItem("userId"))}
                    <div className="container-login100">
                        <div className="wrap-login100 p-b-160 p-t-50">
                            <h2>Problem in reseting Password. Please resend another link</h2>
                            <div className="container-login100-form-btn">

                                <button className="container-login100-form-btn"><a a href="/forgot-password" className="txt1">Resend link</a></button>
                            </div>
                        </div>
                    </div>
                </>
            )
        }


    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        updatePassword: (password) => {
            dispatch(AuthMiddleware.updatePassword(password));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
