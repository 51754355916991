import React, { Component } from "react";
import API from "../../config/api/index";
import { Controlled as CodeMirror } from "react-codemirror2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

require("codemirror/lib/codemirror.css");
require("codemirror/theme/nord.css");
require("codemirror/theme/neat.css");
require("codemirror/mode/xml/xml.js");
require("codemirror/mode/javascript/javascript.js");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class EditAsHTML extends Component {
  constructor(props) {
    super(props);

    // DIFFERENT STATE VARIABLES
    this.state = {
      code: null,
      pageName: "",
      pageLinks: null,
      pageId: null,
      saveSuccess: false,
    };

    this.fetchPage = this.fetchPage.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  UNSAFE_componentWillMount = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page_id = urlParams.get("page_id");
    await this.setState({ pageId: page_id });
    this.fetchPage();
  };

  fetchPage() {
    API.post(`getPage`, {
      page_id: this.state.pageId,
    }).then((res) => {
      var htmlen = res.data.page.html;
      const decoded = decodeURIComponent(escape(window.atob(htmlen)));
      this.setState({
        code: decoded,
        pageName: res.data.page.name,
        pageLinks: res.data.page.links[0],
      });
    });
  }

  onSave() {
    let _this = this;
    var encoded = btoa(unescape(encodeURIComponent(_this.state.code)));
    API.post("page/update", {
      name: this.state.pageName,
      html: encoded,
      user_id: localStorage.getItem("userId"),
      page_id: this.state.pageId,
    });
    this.setState({ saveSuccess: true });
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ saveSuccess: false });
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column" style={{ background: "#2e3440" }}>
          <div
            className="pl-4 pr-4 d-flex justify-content-between sticky-top"
            style={{ background: "#2e3440" }}
          >
            <h1 style={{ color: "#fff", fontWeight: "bold", letterSpacing: 3 }}>
              {this.state.pageName}
            </h1>
            <button
              type="submit"
              className="btn"
              onClick={this.onSave}
              style={{
                margin: 5,
                backgroundColor: "#4ef037",
                fontWeight: "bold",
                height: 50,
              }}
            >
              Save Page
            </button>
          </div>
          <CodeMirror
            value={this.state.code}
            options={{
              mode: "xml",
              theme: "nord",
              lineNumbers: true,
              scrollbarStyle: "native",
              lineWrapping: true,
            }}
            onBeforeChange={(editor, data, code) => {
              this.setState({ code });
            }}
          />
        </div>
        <Snackbar
          open={this.state.saveSuccess}
          autoHideDuration={1000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity="success">
            Your Page is saved successfully.
            <span role="img" aria-label="super">
              👌
            </span>
          </Alert>
        </Snackbar>
      </>
    );
  }
}
