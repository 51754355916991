import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { DataBaseMiddleware } from "../../store/middlewares";
import { StatsCard } from "../../components/StatsCard/StatsCardPage";
import { Colors } from "../../config";
import { withRouter } from "react-router-dom";
import noPage from "../../assets/img/NoPage.png";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

// Loader overlay styles
const loadingOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999, // Ensure it overlays other content
};

class Pages extends Component {
  state = {
    isLoading: false, // Add a state to manage loading
  };

  async componentDidMount() {
    const { GetPages } = this.props;
    try {
      this.setState({ isLoading: true }); // Show loader
      await GetPages(localStorage.getItem("userId"));
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      this.setState({ isLoading: false }); // Hide loader
    }
  }

  deletePage = async (pageId) => {
    const { GetPages } = this.props;
    this.setState({ isLoading: true }); // Show loader

    try {
      await axios.post(`https://magicpages.propstory.com/page/remove`, {
        page_id: pageId,
      });
      await GetPages(localStorage.getItem("userId"));
    } catch (error) {
      console.error("Error deleting page:", error);
    } finally {
      this.setState({ isLoading: false }); // Hide loader
    }
  };

  render() {
    const { pages, history } = this.props;
    const { isLoading } = this.state; // Destructure isLoading from state

    return (
      <div className="content" style={{ position: "relative" }}>
        {isLoading && (
          <div style={loadingOverlayStyle}>
            <CircularProgress size={100} style={{ color: "#F50157" }} />
          </div>
        )}
        <Container fluid className="text-center">
          <Row>
            {pages.length <= 0 || pages.length === undefined ? (
              <img
                alt="no page"
                src={noPage}
                width="60%"
                style={{
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              />
            ) : (
              pages.map((item, index) => (
                <Col lg={4} sm={8} key={item._id}>
                  <StatsCard
                    key={index}
                    MainImage={<img alt="" src={item.image} />}
                    statsColor={Colors.LightBlue}
                    statsHeading={item.name}
                    frame={item.html}
                    statsBtn2Text="Delete Page"
                    statsBtn1Text="Edit Page"
                    onClickEdit={() => {
                      history.push({
                        pathname: "/admin/pageDetails",
                        search: `?page_id=${item._id}`,
                        state: { item },
                      });
                    }}
                    onClickDelete={() => this.deletePage(item._id)}
                  />
                </Col>
              ))
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(states) {
  return {
    userId: states.AuthReducer.user.id,
    pages: states.DataBaseReducer.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetPages: (userId) => {
      return dispatch(DataBaseMiddleware.GetPages(userId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pages));
